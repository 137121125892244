<template lang="">
<v-card class="elevation-0 login-card">
    <LoaderLinear />
    <v-card-text class="pb-0">
      <v-form @submit.prevent="sendForm" autocomplete="off">
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
         label="Correo electrónico"
          required
          outlined
          @blur="$v.email.$touch()"
          name="login"
          type="text"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="flex-wrap justify-center">
      <v-row align-content="stretch" justify="center" class="col-8 col-md-6 col-lg-6  flex-wrap container-btn-recovery-padding">
        <v-col class="d-flex justify-center">
          <v-btn
             class="fvl-btn green-primary"
            elevation="1"
            @click="sendForm"
            :disabled="$v.$invalid"
            >Enviar</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="col-12 mt-0 pa-0">
      </v-row>
      <v-row align-content="center" justify="center" class="mt-0">
        <v-col class="text-center pa-0">
          <router-link
            color="primary"
            class="navbar-brand link-recovery"
            :to="links.login.login"
          >
          Iniciar Sesión
          </router-link>
        </v-col>
      </v-row>
    </v-card-actions>
    <SnackBar />
  </v-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { LoaderLinear, SnackBar, Overlay } from "../../components/_commons";
import { links, beginRequest, endRequest } from "../../helpers/";
export default {
  name: "CardPassRecovery",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  data() {
    return {
      links: links,
      email: "",
    };
  },
  methods: {
    async sendForm() {
      await beginRequest();
      const formData = { email: this.email };
      const { snack, status } = await this.$store.dispatch(
        "createToken",
        formData
      );
      await endRequest(snack, status);
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Debe ingresar un E-mail");
      !this.$v.email.required && errors.push("El E-mail es requerido");
      return errors;
    },
  },
};
</script>
<style scoped>
.link-recovery {
  text-decoration: none; 
}
</style>
