<template lang="">

  <v-card class="elevation-0 login-card">
    
    <v-card-text class="pb-0">
      <v-form @submit.prevent="sendForm" autocomplete="off">
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="Correo electrónico"
          required
          outlined
          @blur="$v.email.$touch()"
          name="login"
          type="text"          
        ></v-text-field>
        
        <v-text-field
          id="password"
          class=""
          :error-messages="passwordErrors"
          :append-icon="showPass ? 'visibility' : 'visibility_off'"
          name="password"
          label="Contraseña"
          outlined
          :type="showPass ? 'text' : 'password'"
          hint=""
          v-model="password"
          @click:append="showPass = !showPass"
          @blur="$v.password.$touch()"
        ></v-text-field>
        <v-checkbox
          class="ma-0 pa-0 check-login"
          default-icon= "stop"
          v-model="rememberMe"
          label="Recuérdarme en este dispositivo"
        ></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions class="flex-wrap">
      <v-col align-content="stretch" justify="center" class="flex-wrap container-btn-login-padding">
         <div class="pb-2 d-flex justify-center">
         <v-btn  
            class="fvl-btn green-primary mb-2"
            elevation="1"
            @click="sendForm"
            :disabled="$v.$invalid"
            >Ingresar
            </v-btn>

          
           </div>
          <v-col class="text-center pa-0">
          <router-link
            color="primary"
            class="navbar-brand link-recovery"
            :to="links.login.createToken"
          >
           ¿Olvidaste tu contraseña?
          </router-link>
        </v-col>
      </v-col>
    </v-card-actions>
    
    <SnackBar />
    <Overlay />
  </v-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { LoaderLinear, SnackBar, Overlay } from "../../components/_commons";
import { links, beginRequest, endRequest } from "../../helpers";

export default {
  name: "CardLogin",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },
  data() {
    return {
      links: links,
      email: "",
      password: "",
      showPass: false,
      rememberMe: false,
    };
  },
  methods: {
    async sendForm() {
      await beginRequest();
      const formData = {
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe,
      };
      const { status, snack } = await this.$store.dispatch("logIn", formData);
      await endRequest(snack, status);
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Debe ingresar un correo electrónico válido");
      !this.$v.email.required && errors.push("Ingresa un correo electrónico");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("La longitud mínima es de 6 caracteres");
      !this.$v.password.required && errors.push("Ingresa una contraseña");
      return errors;
    },
  },
};
</script>
<style scoped>
.link-recovery {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.712);
}
</style>
