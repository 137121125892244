<template lang="">
  <v-app id="body-login">
     <!-- Barra Superior -->
     <div class="navbar-login">
            <span><img src="../../assets/Gbs.svg" class="logotipe-gbs"></span>
     </div>
     <!-- Barra Superior -->
   <div class="d-flex flex-row justify-space-between" >
        <div class="login-panel-left" >
          <span class="cube-left-panel"></span>
        </div>
        <div class="login-panel-center">
          <LoaderLinear />
          <div class="container-card-login">
            <CardPassRecovery />
          </div>
          <div class="footer-fvl-login text-center" cols="12">
            Copyright © {{ new Date().getFullYear() }} GBS Architectural |
            Powered by 
            <span>
              <a href="https://www.filevel.com" target="_blank">Filevel Software ®</a>
            </span>
          </div>
          <SnackBar />
          <Overlay />
        </div>
        <div class="login-panel-right">
          <span class="cube-right-panel"></span>
        </div>
      </div>
  </v-app>
</template>
<script>
import { CardPassRecovery } from "../../components/login";
import { FooterLogin } from "../../components/_partials";

export default {
  name: "PassRecovery",
  components: {
    CardPassRecovery,
    FooterLogin,
  },
};
</script>
