<template lang="">
  <v-card class="elevation-12">
    <LoaderLinear />    
    <v-card-text class="pb-0">
      <v-form @submit.prevent="sendForm">
        <v-text-field
          id="password"
          :error-messages="passwordErrors"
          outlined
          :append-icon="showPass ? 'visibility' : 'visibility_off'"
          name="password"
          label="Ingrese su nueva contraseña"
          :type="showPass ? 'text' : 'password'"
          hint="Al menos 6 caracteres"
          v-model="password"
          @click:append="showPass = !showPass"
          @blur="$v.password.$touch()"
        ></v-text-field>
        <v-text-field
          id="passwordRepeat"
          :error-messages="passwordRepeatErrors"
          outlined
          :append-icon="showPassRepeat ? 'visibility' : 'visibility_off'"
          name="password"
          label="Repita su nueva contraseña"
          :type="showPassRepeat ? 'text' : 'password'"
          hint="Al menos 6 caracteres"
          v-model="passwordRepeat"
          @click:append="showPassRepeat = !showPassRepeat"
          @blur="$v.passwordRepeat.$touch()"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="flex-wrap justify-center">
      <v-row align-content="stretch" justify="center" class="col-8 col-md-6 col-lg-6 flex-wrap">
        <v-col class="d-flex justify-center">
          <v-btn
            color="primary"
            align-self="start"
            class="col-12 btn-login"
            @click="sendForm"
            :disabled="$v.$invalid"
            >Reestablecer</v-btn
          >
        </v-col>
      </v-row>

      <v-row align-content="center" justify="center">
        <v-col class="text-center">
          <router-link 
          color="primary"
          class="navbar-brand link-recovery"
          :to="links.login.login">
            Iniciar Sesión
          </router-link>
        </v-col>
      </v-row>
    </v-card-actions>

    <SnackBar />
    <Overlay />
  </v-card>
</template>
<script>
import router from "../../router";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { LoaderLinear, SnackBar, Overlay } from "../../components/_commons";
import { beginRequest, endRequest, links } from "../../helpers";

export default {
  name: "CardResetPassword",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
  },
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(6) },
    passwordRepeat: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs("password"),
    },
  },
  data() {
    return {
      links: links,
      password: "",
      passwordRepeat: "",
      showPass: false,
      showPassRepeat: false,
    };
  },
  methods: {
    async sendForm() {
      await beginRequest();
      const formData = {
        password: this.password,
        token: this.$route.params.token,
      };
      const { status, snack } = await this.$store.dispatch(
        "resetPassword",
        formData
      );
      await endRequest(snack, status);
      
      if (status >= 200 && status < 300) {
        setTimeout(() => {
          router.push(links.login.login);
        }, 4000);
      }
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("La longitud mínima es de 6 caracteres");
      !this.$v.password.required && errors.push("La contraseña es requerida");
      return errors;
    },
    passwordRepeatErrors() {
      const errors = [];
      if (!this.$v.passwordRepeat.$dirty) return errors;
      !this.$v.passwordRepeat.minLength &&
        errors.push("La longitud mínima es de 6 caracteres");
      !this.$v.passwordRepeat.required &&
        errors.push("La contraseña es requerida");
      !this.$v.passwordRepeat.sameAsPassword &&
        errors.push("las constraseñas no coinciden");
      return errors;
    },
  },
};
</script>
<style scoped>
.link-recovery {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.712);
}
</style>
